export default {
  name: null,
  image: null,
  category: null,
  title: null,
  wiki: null,
  wikiLink: null,
  department: null,
  departmentLink: null,
  badge: null,
  keywords: null,
  zipcode: null,
  facebook: null,
  twitter: null,
  instagram: null,
  resources: [],
  dob: null,
  charges: {},
  salary: null,
};
