import React from 'react';
import './Footer.scss';

export default function () {
  return (
    <footer>
      <b>Fedbook</b> © 2020
      <br />
      Government Employee Index
    </footer>
  );
}
